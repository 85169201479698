import {
  getClientInfo,
  getSensitiveClientInfo,
  getSensitiveClientInfoForInquiry,
} from '@/api/index.js';
import { mapGetters } from 'vuex';

export const myMixin = {
  data() {
    return {
      allData: {},
      paramsId: {
        cooperationPresidentId: '',
      },
      publicDialogVisible: false,
      PresidentCooperateWillingness: [],
      privacyType: 1,
    };
  },
  created() {
    this.paramsId.cooperationPresidentId =
      this.getUserInfo.userVo &&
      this.getUserInfo.userVo.cooperationPresidentId;
  },
  computed: {
    ...mapGetters(['getUserInfo']),
  },
  methods: {
    showDialogVisible(row, boole = false, inquiry = false) {
      this.publicDialogVisible = true;
      this.paramsId.clientId = row.clientId || row.refId;
      this.paramsId.dealStatus = row.dealStatus || '';
      this.paramsId.clientPresidentRelatedId =
        row.clientPresidentRelatedId || '';
      this.privacyType = boole ? 1 : 2;
      this.triggerGetClientInfo(boole, inquiry);
    },
    async triggerGetClientInfo(boole, inquiry) {
      // if (boole) {
      //   this.allData = await getSensitiveClientInfo(this.paramsId);

      // } else {
      //   this.allData = await getClientInfo(this.paramsId);

      // }

      let res = {};
      if (boole && !inquiry) {
        res = await getSensitiveClientInfo(this.paramsId);
      } else if (boole && inquiry) {
        res = await getSensitiveClientInfoForInquiry(this.paramsId);
        if (!res.financeRequirementList) {
          res.financeRequirementList = [];
        }
      } else if (!boole) {
        res = await getClientInfo(this.paramsId);
      }
      if (res.clientBase.businessInformationAttachment) {
        res.clientBase.businessInformationAttachment = JSON.parse(
          res.clientBase.businessInformationAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.clientBase.capitalizeEnterpriseCreditAttachment) {
        res.clientBase.capitalizeEnterpriseCreditAttachment = JSON.parse(
          res.clientBase.capitalizeEnterpriseCreditAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.personsList.length) {
        res.personsList.forEach((element) => {
          if (element.controlPersonAttachment) {
            element.controlPersonAttachment = JSON.parse(
              element.controlPersonAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
          if (element.personalCreditAttachment) {
            element.personalCreditAttachment = JSON.parse(
              element.personalCreditAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
          if (element.otherAttachment) {
            element.otherAttachment = JSON.parse(
              element.otherAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
        });
      }
      if (res.clientBase.capitalizeAttachment) {
        res.clientBase.capitalizeAttachment = JSON.parse(
          res.clientBase.capitalizeAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.personalAssetsList.length) {
        res.personalAssetsList.forEach((element) => {
          if (element.assetAttachment) {
            element.assetAttachment = JSON.parse(
              element.assetAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
        });
      }
      if (res.capitalizesList.length) {
        res.capitalizesList.forEach((element) => {
          if (element.guaranteeAttachment) {
            element.guaranteeAttachment = JSON.parse(
              element.guaranteeAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
        });
      }

      if (res.clientFinanceVO.balanceSheetAttachment) {
        res.clientFinanceVO.balanceSheetAttachment = JSON.parse(
          res.clientFinanceVO.balanceSheetAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.clientFinanceVO.cashFlowStatementAttachment) {
        res.clientFinanceVO.cashFlowStatementAttachment = JSON.parse(
          res.clientFinanceVO.cashFlowStatementAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.clientFinanceVO.incomeStatementAttachment) {
        res.clientFinanceVO.incomeStatementAttachment = JSON.parse(
          res.clientFinanceVO.incomeStatementAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.clientFinanceVO.taxPaymentCertificateAttachment) {
        res.clientFinanceVO.taxPaymentCertificateAttachment =
          JSON.parse(
            res.clientFinanceVO.taxPaymentCertificateAttachment
          ).map((item) => ({
            name: item.fileName,
            url: item.filePath,
          }));
      }
      if (res.clientFinanceVO.taxReturnsAttachment) {
        res.clientFinanceVO.taxReturnsAttachment = JSON.parse(
          res.clientFinanceVO.taxReturnsAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (
        res.financeRequirementList &&
        res.financeRequirementList.length
      ) {
        res.financeRequirementList.forEach((element) => {
          if (element.reqRelevantAttachment) {
            element.reqRelevantAttachment = JSON.parse(
              element.reqRelevantAttachment
            ).map((item) => ({
              name: item.fileName,
              url: item.filePath,
            }));
          }
        });
      }
      if (res.otherKeyInfo.taxPaymentCertificateAttachment) {
        res.otherKeyInfo.taxPaymentCertificateAttachment = JSON.parse(
          res.otherKeyInfo.taxPaymentCertificateAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.otherKeyInfo.vatReturnAttachment) {
        res.otherKeyInfo.vatReturnAttachment = JSON.parse(
          res.otherKeyInfo.vatReturnAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.otherKeyInfo.incomeTaxReturnAttachment) {
        res.otherKeyInfo.incomeTaxReturnAttachment = JSON.parse(
          res.otherKeyInfo.incomeTaxReturnAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.otherKeyInfo.otherAttachment) {
        res.otherKeyInfo.otherAttachment = JSON.parse(
          res.otherKeyInfo.otherAttachment
        ).map((item) => ({
          name: item.fileName,
          url: item.filePath,
        }));
      }
      if (res.riskScreeningInformationList.length) {
        res.riskScreeningInformationList.forEach((item) => {
          if (!item.tryingToStudy) {
            item.tryingToStudy = '--';
          }
        });
      }
      if (res.checkedTitleField) {
        res.checkedTitleField = res.checkedTitleField.split(',');
        res.ischeckedTitleField = true;
      } else {
        res.ischeckedTitleField = false;

        res.checkedTitleField = [
          'financeRequirement',
          'clientBase',
          'clientBase_businessInfo',
          'clientBase_historyLitigation',
          'clientBase_shareholder',
          'clientBase_historicalCredit',
          'clientBase_cooperationBank',
          'clientKeyInfo',
          'controlPerson',
          'clientPersonalAssets',
          'businessCase',
          'businessCase_businessArea',
          'businessCase_businessDownstream',
          'businessCase_businessUpstream',
          'businessCase_mainSupplier',
          'businessCase_downClient',
          'businessCase_productIntroduction',
          'businessCase_description',
          'businessCase_coreCompetitivenessVista',
          'businessCase_shortBoardProblems',
          'clientFinance',
          'clientFinance_subject',
          'clientFinance_quota',
          'clientFinance_analyse',
          'taxInfo',
          'bankFlow',
          'bankFlow_flowInfo',
          'bankFlow_topTen',
          'clientCapitalize',
          'capitalizeEnterpriseCreditAttachment_attachment',
          'controlPersonAttachment_attachment',
          'personalCreditAttachment_attachment',
          'otherAttachment_attachment'
        ];
      }
      this.allData = res;
    },
  },
};
