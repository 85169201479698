<template>
	<div class="scheme">
		<div>
			<div class="my-title">
				<p>客户的方案</p>
			</div>
			<div
				style="
					padding: 0 0 20px 20px;
					box-shadow: 0px 3px 3px 1px #e7e7e7;
					position: relative;
					z-index: 10;
				"
			>
				<div
					style="
						padding: 10px 0 0 0;
						display: flex;
						align-items: center;
					"
				>
					<span>客户名称:</span>
					<el-input
						style="width: 20%; margin: 0 10px"
						placeholder="请输入客户名称"
						v-model="ruleForm.clientName"
					></el-input>
					<div style="margin: 0 10px">
						<el-button
							type="primary"
							@click="
								() => {
									totalRows = 0;

									ruleForm.pageNo = 1;
									getSchemeInfoList(true);
								}
							"
							>查询</el-button
						>

						<el-button
							type="primary"
							@click="
								() => {
									totalRows = 0;

									ruleForm.clientName = '';
									ruleForm.pageNo = 1;
									getSchemeInfoList(true);
								}
							"
							>清空</el-button
						>
					</div>
				</div>
			</div>
		</div>
		<div
			v-if="isempty"
			class="test-1"
			v-infinite-scroll="load"
			infinite-scroll-disabled="disabled"
			style="
				overflow: auto;
				height: calc(100vh - 260px);
				padding: 0 0 10px 0;
			"
		>
			<div
				style="padding: 10px"
				v-for="(item, index) in list"
				:key="index"
			>
				<el-card class="box-card">
					<div
						class="desc"
						:class="{
							activeColor:
								item.schemeStatus == 4 ||
								item.schemeStatus == 5,
						}"
					>
						{{
							item.schemeStatus == 4 || item.schemeStatus == 5
								? '进行中'
								: '已完结'
						}}
					</div>
					<div style="position: relative">
						<p
							@click="showDialogVisible(item)"
							class="content-title"
							style="
								margin: 10px;
								font-size: 22px;
								font-weight: 800;
								color: #23924f;
								margin: 0 0 20px 0;
								cursor: pointer;
							"
						>
							{{ item.clientName }}
							<span
								style="color: red"
								v-if="
									item.schemeStatusName == '失败' ||
									item.schemeStatusName == '放弃'
								"
								>({{ item.schemeStatusName }})</span
							>
						</p>
					</div>

					<div style="padding: 0 10px">
						<p class="content-title">需求信息</p>
						<div class="content-box">
							<div>
								<p>客户名称：{{ item.clientName }}</p>
								<p>贷款类型：{{ item.loanTypeName }}</p>
								<p>
									期望利率：{{ item.minExpectRate }} 至
									{{ item.maxExpectRate }}
								</p>
								<p>
									需求金额(万元)：{{ item.minFinancing }} 至
									{{ item.maxFinancing }}
								</p>
							</div>
							<div>
								<p>
									时效要求(天)：{{
										item.minPrescriptionRequirement
									}}
									至 {{ item.maxPrescriptionRequirement }}
								</p>
								<p>
									贷款期限(月)：{{ item.minLoanTerm }} 至
									{{ item.maxLoanTerm }}
								</p>
								<p>还款来源：{{ item.repaymentSource }}</p>
								<p>贷款用途：{{ item.paymentPurpose }}</p>
							</div>
						</div>
					</div>
					<div style="padding: 0 10px">
						<p class="content-title">方案信息</p>
						<div class="content-boxs">
							<div>
								<div>
									<p>
										产品名称：{{
											item.financialProductName
										}}
									</p>
									<p>
										融资金额(万元)：{{
											item.schemeAmountMin
										}}
									</p>
									<!-- <p>
									融资金额(max)(万元)：{{
										item.schemeAmountMax
									}}
								</p> -->
									<p>
										利率/费率(%)：{{ item.interestRateMin }}
									</p>
									<!-- <p>
									利率/费率最大值(%)：{{
										item.interestRateMax
									}}
								</p> -->
									<p>融资期限(月)：{{ item.loanDeadline }}</p>
								</div>
								<div>
									<p>还款方式：{{ item.repaymentMethod }}</p>
									<p>
										融资方案概述：{{
											item.financingPlanSummary
										}}
									</p>
									<p>
										担保条件概述：{{
											item.guaranteeConditionsSummary
										}}
									</p>
								</div>
							</div>
							<p style="line-height: 24px">
								银行其他希望条件：{{ item.bankOtherHopes }}
							</p>
							<p style="line-height: 24px">
								银行其他硬性要求：{{
									item.bankOtherRequirements
								}}
							</p>
						</div>
					</div>
					<div style="position: relative">
						<myStateItem :item="item" :stateList="stateList" />
						<div style="display: flex; justify-content: flex-end">
							<el-button
								type="primary"
								v-if="
									item.schemeStatus == 4 ||
									item.schemeStatus == 5
								"
								@click="editData(item, 'stateVisible')"
								>方案流转到下一环节
							</el-button>
							<el-button
								type="primary"
								@click="editData(item, 'editVisible')"
								v-if="
									item.schemeStatus == 4 ||
									item.schemeStatus == 5
								"
								>修改
							</el-button>
							<el-button type="primary" @click="feedback(item)"
								>反馈进度及意见
							</el-button>
						</div>
					</div>
				</el-card>
			</div>
			<div v-if="list.length">
				<p
					style="text-align: center; color: rgba(0, 0, 0, 0.45)"
					v-if="loading"
				>
					加载中
				</p>
				<p
					style="text-align: center; color: rgba(0, 0, 0, 0.45)"
					v-if="noMore"
				>
					已加载全部数据
				</p>
			</div>
		</div>
		<div style="padding-top: 65px" v-else>
			<el-empty description="没有找到符合条件的数据"></el-empty>
		</div>
		<publicReply :paramsId="params" :replyVisible.sync="replyVisible" />
		<publicInfoDrawer
			:allData="allData"
			:privacyType="privacyType"
			:dialogVisible.sync="publicDialogVisible"
			:paramsId="paramsId"
			@closeVisible="publicDialogVisible = false"
		/>
		<editDrawer
			@successVisible="editSuccess"
			@closeVisible="editVisible = false"
			:params="params"
			:editVisible.sync="editVisible"
		/>
		<stateChange
			@success="success"
			:stateList="stateList"
			:item="params"
			:stateVisible.sync="stateVisible"
			:SchemeFailReasonTypeList="SchemeFailReasonTypeList"
		/>
	</div>
</template>

<script>
import { myMixin } from '@/mixins';

import { schemeInfoList, itemByType } from '@/api/index.js';
import myStateItem from './module/stateItem.vue';
import editDrawer from './module/editDrawer.vue';
import stateChange from './module/stateChange.vue';
export default {
	name: 'scheme',
	mixins: [myMixin],
	data() {
		return {
			ruleForm: { clientName: '', pageSize: 10, pageNo: 1 },
			list: [],
			totalRows: 0,
			loading: false,
			replyVisible: false,
			editVisible: false,
			stateVisible: false,
			params: {
				businessType: 9,
			},
			stateList: [],
			isempty: true,
			SchemeFailReasonTypeList: [],
		};
	},
	methods: {
		async getSchemeInfoList(b = false) {
			const res = await schemeInfoList(this.ruleForm);
			if (res.returncode == 0) {
				if (b) {
					this.list = [];
				}
				setTimeout(() => {
					this.list = this.list.concat(res.list);
					this.totalRows = res.totalRows;
					this.isempty = this.list.length ? true : false;
				});
			}
		},
		load() {
			this.ruleForm.pageNo = this.ruleForm.pageNo + 1;
			this.loading = true;
			this.getSchemeInfoList();
			this.loading = false;
		},
		feedback(item) {
			this.params = item;
			this.params.businessType = 9;

			this.replyVisible = true;
		},
		editSuccess(obj) {
			let index = this.list.findIndex(
				(item) => item.schemeId === obj.schemeId
			);
			this.list.splice(index, 1, obj);
			this.editVisible = false;
		},
		success(data) {
			let index = this.list.findIndex(
				(item) => item.schemeId == data.schemeId
			);
			this.stateVisible = false;

			this.$set(this.list[index], 'schemeLink', data.schemeLink);
			this.$set(this.list[index], 'schemeStatus', data.schemeStatus);
		},
		editData(item, Visible) {
			this.params = item;
			this[Visible] = true;
		},
		async getSchemeStatus() {
			const res = await itemByType({
				dictionaryType: 'SchemeLink',
			});
			if (res.returncode === 0) {
				this.stateList = res.data;
			}
		},
		async getSchemeFailReasonTypeList() {
			const res = await itemByType({
				dictionaryType: 'SchemeFailReasonType',
			});
			if (res.returncode === 0) {
				this.SchemeFailReasonTypeList = res.data.map((item) => ({
					value: item.itemValue,
					label: item.itemName,
				}));
			}
		},
	},
	created() {
		this.getSchemeStatus();
		this.getSchemeFailReasonTypeList();
		this.getSchemeInfoList();
	},
	computed: {
		noMore() {
			return this.list.length >= this.totalRows;
		},
		disabled() {
			return this.loading || this.noMore;
		},
	},
	components: {
		myStateItem,
		editDrawer,
		stateChange,
	},
};
</script>
<style lang="scss" scoped>
div {
	box-sizing: border-box;
}
.scheme {
	width: 100%;
	background: #fff;
	position: relative;
}
.content-title {
	color: #000;
	font-weight: 800;
}
.content-box {
	display: flex;
	padding: 0 10px;
	div {
		flex: 1;
	}
	p {
		font-size: 14px;
		color: #888;
		margin: 20px 10px;
	}
}
.content-boxs {
	padding: 0 10px;
	> div {
		display: flex;
		justify-content: space-between;
		> div {
			flex: 1;
		}
		p {
			font-size: 14px;
			color: #888;
			margin: 20px 10px;
		}
	}
	> p {
		font-size: 14px;
		color: #888;
		margin: 0 10px;
	}
	> p {
		margin-bottom: 10px;
	}
}
.desc {
	position: absolute;
	top: -53px;
	right: -78px;
	z-index: 5;
	color: #fff;
	background: #409eff;
	display: block;
	transform: rotateZ(45deg);
	width: 180px;
	transform-origin: bottom left;
	height: 30px;
	text-align: center;
	line-height: 30px;
}
.activeColor {
	background: #f64c4c;
}
.box-card {
	position: relative;
}
</style>
