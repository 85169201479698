<template>
	<div v-if="stateList.length" class="busi-state-main">
		<div class="vux-flexbox busi-state vux-flex-row" style="opacity: 1">
			<div
				class="busi-state-item el-popover__reference"
				:class="{
					'state-suc': item.schemeLink >= stateList[0].itemValue,
				}"
				aria-describedby="el-popover-5977"
				tabindex="0"
			>
				{{ stateList[0].itemName }}
				<div class="state-circle circle-left"></div>
				<div class="state-arrow arrow-right"></div>
			</div>

			<div
				class="busi-state-item el-popover__reference"
				:class="{ 'state-suc': item.schemeLink >= myItem.itemValue }"
				aria-describedby="el-popover-3939"
				v-for="(myItem, index) in filterStateList()"
				tabindex="0"
				:key="index"
			>
				{{ myItem.itemName }}
				<div class="state-arrow arrow-left"></div>
				<div class="state-arrow arrow-right"></div>
			</div>

			<div
				v-if="
					item.schemeLink == stateList[stateList.length - 1].itemValue
				"
				:class="[item.schemeStatus == 1 ? 'state-suc' : 'state-fail']"
				class="busi-state-item el-popover__reference"
				aria-describedby="el-popover-508"
				tabindex="0"
			>
				<i
					v-if="item.schemeStatus == 2"
					class="el-icon-circle-close el-icon--right"
					style="margin-right: 8px"
				></i>
				<i
					v-else
					class="el-icon-check el-icon--right"
					style="margin-right: 8px"
				></i>
				{{ stateList[stateList.length - 1].itemName }} -
				{{ item.schemeStatus == 1 ? '成功' : '失败' }}

				<div class="state-arrow arrow-left"></div>
				<div class="state-circle circle-right"></div>
			</div>
			<div
				v-else
				class="busi-state-item el-popover__reference"
				aria-describedby="el-popover-508"
				tabindex="0"
			>
				{{ stateList[stateList.length - 1].itemName }}

				<div class="state-arrow arrow-left"></div>
				<div class="state-circle circle-right"></div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		stateList: {
			type: Array,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {};
	},
	methods: {
		filterStateList() {
			if (!this.stateList.length > 0) return [];
			let newArr = JSON.parse(JSON.stringify(this.stateList));
			newArr.shift();
			newArr.pop();
			return newArr;
		},
	},
	created() {},
	computed: {},
};
</script>
<style lang="scss" scoped>
.busi-state-main {
	// padding: 15px;
	// border-top: 1px solid #e6e6e6;
	// border-bottom: 1px solid #e6e6e6;
	// background-color: #fff;
}
.busi-state {
	position: relative;
	padding-left: 20px;
	overflow-x: auto;
	overflow-y: hidden;
	z-index: 1;
	width: 100%;
	text-align: left;
	flex-direction: row;
	display: flex;
	justify-content: flex-start;
	padding-left: 140px;
}

.busi-state-item {
	padding: 12px 30px;
	height: 38px;
	position: relative;
	margin: 5px 5px 5px 0;
}

.busi-state-item .state-circle {
	width: 38px;
	height: 38px;
	border-radius: 19px;
	background-color: #fff;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	position: absolute;
	z-index: -1;
}

.circle-left {
	top: -0px;
	left: -17px;
}

.busi-state-item .state-arrow {
	width: 38px;
	height: 38px;
	-webkit-transform: scale(0.707) rotate(45deg);
	transform: scale(0.707) rotate(45deg);
	background-color: #fff;
	position: absolute;
}

.arrow-right {
	z-index: 1;
	top: -0px;
	right: -19px;
}

.arrow-left {
	top: -0px;
	left: -19px;
}

.circle-right {
	z-index: 1;
	top: -0px;
	right: -15.5px;
}

.busi-state-item {
	background-color: #eaeaea;
	color: #666666;
}
.busi-state-item .circle-left,
.busi-state-item .circle-right {
	background-color: #eaeaea;
}
.busi-state-item .arrow-right {
	background-color: #eaeaea;
}
.busi-state-item.arrow-left {
	background-color: #fff;
}
.busi-state-item .circle-left,
.busi-state-item .circle-right {
	background-color: #eaeaea;
}
.state-suc {
	background-color: #00ca9d;
	color: #fff;
}
.state-suc .circle-left,
.state-suc .circle-right {
	background-color: #00ca9d;
}
.state-suc .arrow-right {
	background-color: #00ca9d;
}

.state-suc .arrow-left {
	background-color: #fff;
}

.state-fail {
	background-color: #ff6767;
	color: #fff;
}
.state-fail .circle-left,
.state-fail .circle-right {
	background-color: #ff6767;
}
</style>
