<template>
	<el-dialog
		:append-to-body="true"
		title="方案环节流转"
		:visible.sync="stateVisible"
		width="30%"
		:before-close="handleClose"
		@open="open"
		@close="
			() => {
				$refs['stateRuleForm'].resetFields();
				Object.assign($data, $options.data());
			}
		"
	>
		<el-form
			v-if="item.schemeLink < 4"
			:model="ruleForm"
			status-icon
			ref="stateRuleForm"
			class="demo-ruleForm"
			label-position="rigth"
			label-width="120px"
		>
			<el-form-item label="下一方案环节:">
				{{ stateList[item.schemeLink].itemName }}
			</el-form-item>
			<el-form-item
				:rules="[
					{
						required: true,
						message: '审批结束时间',
					},
				]"
				prop="linkStartTime"
				v-if="item.schemeLink == 3"
				label="审批结束时间:"
			>
				<el-date-picker
					type="datetime"
					placeholder="请选择时间"
					v-model="ruleForm.linkStartTime"
					style="width: 100%"
					value-format="yyyy-MM-dd HH:mm"
				></el-date-picker>
			</el-form-item>
			<el-form-item
				:rules="[
					{
						required: true,
						message: '请选择开始时间',
					},
				]"
				prop="linkStartTime"
				v-else
				label="开始时间:"
			>
				<el-date-picker
					type="datetime"
					placeholder="请选择时间"
					v-model="ruleForm.linkStartTime"
					style="width: 100%"
					value-format="yyyy-MM-dd HH:mm"
				></el-date-picker>
			</el-form-item>
			<el-form-item
				:rules="[
					{
						required: true,
						message: '请选择方案状态',
					},
				]"
				prop="schemeStatus"
				v-if="item.schemeLink == 3"
				label="方案状态:"
			>
				<el-radio v-model="ruleForm.schemeStatus" label="1"
					>成功</el-radio
				>

				<el-radio v-model="ruleForm.schemeStatus" label="2"
					>失败</el-radio
				>
			</el-form-item>
			<el-form-item
				v-if="item.schemeLink == 3 && ruleForm.schemeStatus == 2"
				style="margin-bottom: 10px"
				label="原因类别:"
			>
				<el-select
					style="width: 100%"
					v-model="ruleForm.circulationReasonType"
					placeholder="请选择"
				>
					<el-option
						v-for="item in SchemeFailReasonTypeList"
						:key="item.value"
						:label="item.label"
						:value="item.value"
					>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item
				v-if="item.schemeLink == 3 && ruleForm.schemeStatus == 2"
				label="原因描述:"
			>
				<el-input
					type="textarea"
					:autosize="{ minRows: 2, maxRows: 14 }"
					placeholder="请输入内容"
					v-model="ruleForm.circulationReason"
				></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="$emit('update:stateVisible', false)"
				>取 消</el-button
			>
			<el-button @click="postFmFinanceSubject" type="primary"
				>确 定</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
import { schemeLinkChange } from '@/api/index.js';

export default {
	name: 'changeDrawer',
	props: {
		stateVisible: {
			type: Boolean,
			required: true,
		},
		item: {
			type: Object,
			required: true,
		},
		stateList: {
			type: Array,
			required: true,
		},
		SchemeFailReasonTypeList: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			ruleForm: {
				schemeId: '',
				schemeLink: '',
				linkStartTime: '',
				schemeStatus: '',
				oldSchemeLink: '',
				circulationReasonType: '',
				circulationReason: '',
			},
		};
	},
	methods: {
		postFmFinanceSubject() {
			this.$refs['stateRuleForm'].validate(async (valid) => {
				if (valid) {
					this.ruleForm.schemeId = this.item.schemeId;
					this.ruleForm.schemeLink = this.item.schemeLink + 1;
					this.ruleForm.oldSchemeLink = this.item.schemeLink;
					const res = await schemeLinkChange(this.ruleForm);
					if (res.returncode == 0) {
						this.$message({
							message: '扭转成功',
							type: 'success',
						});
						this.$emit('success', this.ruleForm);
					}
				}
			});
		},
		handleClose(done) {
			this.$emit('update:stateVisible', false);
		},
		open() {
			if (this.item.schemeLink != 3) {
				this.ruleForm.schemeStatus = this.item.schemeStatus;
			}
		},
	},
	created() {},
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item {
	margin-bottom: 0px;
}
</style>
<style>
.el-dialog__body {
	padding: 10px !important;
}
</style>
